import React, { useState, useEffect, useRef, useCallback } from "react";
import "./CertificateEditor2708.css";

const CertificateEditor2708 = () => {
  const [name, setName] = useState("");
  const [backgroundSrc] = useState("./images/bg-2708.jpg");
  const [expectWidth, setExpectWidth] = useState(80);

  const canvasRef = useRef(null);
  const imgBackgroundRef = useRef(null);

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
      img.src = src;
    });
  };

  useEffect(() => {
    const loadImages = async () => {
      try {
        imgBackgroundRef.current = await loadImage(backgroundSrc);
        updateCertificate();
      } catch (error) {
        console.error("Lỗi khi tải hình ảnh:", error);
      }
    };
    loadImages();
  }, [backgroundSrc]);

  const updateCertificate = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas || !imgBackgroundRef.current) {
      return;
    }
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(imgBackgroundRef.current, 0, 0, canvas.width, canvas.height);

    ctx.textAlign = "center";
    ctx.fillStyle = "#000000";
    ctx.font = "500 60px UTMNovido";
    ctx.fillText(name, 345, 437);
  }, [expectWidth, name]);

  const requestUpdate = useCallback(() => {
    requestAnimationFrame(updateCertificate);
  }, [updateCertificate]);

  useEffect(() => {
    requestUpdate();
  }, [expectWidth, requestUpdate]);

  const handleNameChange = (e) => {
    setName(e.target.value);
    updateCertificate();
  };

  const handleDownloadClick = () => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas không tồn tại");
      return;
    }
    const image = canvas.toDataURL();
    const tmpLink = document.createElement("a");
    tmpLink.download = "certificate.png";
    tmpLink.href = image;
    document.body.appendChild(tmpLink);
    tmpLink.click();
    document.body.removeChild(tmpLink);
  };

  return (
    <main>
      <div className="container">
        <div className="input-row justify-center">
          <div className="row-name flex">
            <input
              className="full_name"
              id="id_input_name"
              type="text"
              name="fname"
              placeholder="Họ và tên..."
              value={name}
              onChange={handleNameChange}
              autoFocus
            />
          </div>
        </div>
        <div className="canvas-area">
          <div className="flex canvas-row justify-center">
            <canvas id="id_main_canvas" ref={canvasRef} width="680" height="947"></canvas>
          </div>
        </div>
        <div className="download-btn flex justify-center">
          <button id="id_download" hidden onClick={handleDownloadClick}>
            Download
          </button>
          <label className="download_btn" htmlFor="id_download" id="download">
            <i className="fa fa-download"></i>&nbsp;Download
          </label>
        </div>
      </div>
    </main>
  );
};

export default CertificateEditor2708;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import CertificateEditor2407 from './CertificateEditor2407';
import CertificateEditor2508 from './CertificateEditor2508';
import CertificateEditor2708 from './CertificateEditor2708';

function App() {
  return (
    <Router>
      <Routes>
        {/* Define the route for the CertificateEditor */}
        <Route path="/hieu-de-thuong-24-7" element={<CertificateEditor2407 />} />
        <Route path="/hieu-de-thuong-25-8" element={<CertificateEditor2508 />} />
        <Route path="/" element={<CertificateEditor2708 />} />
        
        {/* Optionally, define a home page or redirect */}
        {/* <Route path="/" element={<div className="App"><h1>Welcome to the Home Page</h1></div>} /> */}

        {/* Fallback for undefined routes */}
        {/* <Route path="*" element={<div className="App"><h1>404 - Page Not Found</h1></div>} /> */}
      </Routes>
    </Router>
  );
}

export default App;

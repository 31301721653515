import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Developer Tools Detection and Prevention (only in production mode)
// if (process.env.NODE_ENV === 'production') {
//   (function() {
//     const devtools = {
//       open: false,
//       orientation: null
//     };
//     const threshold = 160;

//     const checkDevTools = () => {
//       const widthDifference = window.outerWidth - window.innerWidth;
//       const heightDifference = window.outerHeight - window.innerHeight;
//       const isDocked = widthDifference > threshold || heightDifference > threshold;
//       const isSeparateWindow = widthDifference < threshold && heightDifference < threshold && (widthDifference !== 0 || heightDifference !== 0);

//       const orientation = widthDifference > heightDifference ? 'vertical' : 'horizontal';

//       if (isDocked || isSeparateWindow) {
//         if (!devtools.open || devtools.orientation !== (isSeparateWindow ? 'separate' : orientation)) {
//           emitEvent(true, isSeparateWindow ? 'separate' : orientation);
//         }
//         devtools.open = true;
//         devtools.orientation = isSeparateWindow ? 'separate' : orientation;
//       } else {
//         if (devtools.open) {
//           emitEvent(false, null);
//         }
//         devtools.open = false;
//         devtools.orientation = null;
//       }
//     };

//     const emitEvent = (state, orientation) => {
//       window.dispatchEvent(new CustomEvent('devtoolschange', {
//         detail: {
//           open: state,
//           orientation: orientation
//         }
//       }));
//     };

//     // Initial check
//     checkDevTools();

//     // Periodic checks
//     setInterval(checkDevTools, 500);

//     // Listen for the custom devtoolschange event
//     window.addEventListener('devtoolschange', event => {
//       if (event.detail.open) {
//         document.body.innerHTML = '<h1>Developer Tools are disabled on this site</h1>';
//       }
//     });

//     // Additional protection by detecting specific function overrides
//     const element = new Image();
//     Object.defineProperty(element, 'id', {
//       get: function() {
//         devtools.open = true;
//         emitEvent(true, devtools.orientation);
//         return 'devtools';
//       }
//     });

//     console.log('%c', element);

//   })();
// }



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

